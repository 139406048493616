import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Title from "../Title";
function Nosotros() {
	return (
		<div className="section">
			<Container>
				<Row className="py-4">
					<Col>
						<Title width="77%" title="Nosotros" color="gray" />
					</Col>
				</Row>
				<Row className="pt-4">
					<Col sm="6">
						<img
							style={{ width: "100%" }}
							src={
								require("../../assets/img/verticeaustral/nosotros.jpg").default
							}
							alt="img-nosotros"
						/>
					</Col>
					<Col sm="6">
						<p className="description pt-4 pt-1">
							En Vértice Austral nos encanta tener el barro hasta el cuello y
							llegar a lugares únicos y desconocidos. Nuestra misión es poder
							llevar estos lugares a miles de personas de una manera
							colaborativa y consciente con el medio ambiente. Lo más importante
							de nuestros proyectos es la conservación y el cuidado de la flora
							y fauna.
						</p>
						<Button tag={Link} className="mt-3" color="primary" to="contactanos">
							CONTÁCTANOS
						</Button>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
export default Nosotros;
