import React from "react";
import { Container, Row, Col } from "reactstrap";
import FormularioVenta from "../Formulario/venta";
import { Background } from "../Styles";
import Image from "../../assets/img/verticeaustral/tuproyecto.jpg";
import Title from "../Title";

function Vende() {
	return (
		<Background src={Image}>
			<Container>
				<Row className="pt-md-0">
					<Col className="pt-md-4">
						<Title
							title="¿Quieres Vender tu campo?"
							bgcolor="white"
							color="white"
							width="0"
						/>
					</Col>
                    <Col md="4" className="py-3 mt-md-3 mt-3 bg-white mx-2">
						<p className="text-center px-1 pt-2">
							Bajo el modelo colaborativo podemos mejorar tus expectativas de
							venta.
						</p>
                        <p className="text-center h6 small">¿Quieres saber mas? </p>
                        <p className="text-center">
                            Completa el siguiente formulario y nos
							colocaremos en contacto a la brevedad
                        </p>
						<FormularioVenta />
					</Col>
				</Row>
				
			</Container>
		</Background>
	);
}
export default Vende;
