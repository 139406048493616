import React from "react";
import ReactDOM from "react-dom";
// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import App from './App'

// others

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
