import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Formulario from '../Formulario'
import Title from '../Title'
import { Background } from 'components/Styles'
import Image from 'assets/img/verticeaustral/servicios4.jpg'
function Contactanos(){
    return(
        <div className="contacto">
            <Background src={Image}>
                <Container>
                    <Row className="pt-3">
                        <Col className="pt-md-4">
                            <Title title="Contáctanos" bgcolor="white" width="0"/>
                            <div className="pt-md-5">
                                <p className="text-primary h2">¿Quieres mas información?</p>
                                <p className="text-white h4">ENVIANOS TUS DATOS Y TE CONTACTAREMOS</p>
                            </div>
                        </Col>
                        <Col className="pt-md-4 mt-md-5" md="5">
                            <Formulario/>
                        </Col>     
                    </Row>
                </Container>
            </Background>
        </div>
    )
}
export default Contactanos