import React from 'react'

function Icon(props){
    return(
        <div style={{width:'100%',height:'100%',padding:0}}>
            <svg xmlns="http://www.w3.org/2000/svg"  
                width="75px" 
                height="26px" 
                viewBox="0 0 130 135.33"
                className="p-0"
                
            >
                    {props.children}
            </svg>
        </div>
    )
}

function Webpay(){
    return(
        <Icon>
            <defs>
                <clipPath id="clip1">
                    <path d="M 380 36 L 450.109375 36 L 450.109375 107 L 380 107 Z M 380 36 "/>
                </clipPath>
            </defs>
            <g id="surface1">
                    <path style={{stroke:'none',fillRule:'nonzero',fill:'rgb(0.799561%,0.799561%,1.199341%)',fillOpacity:1}} d="M 0 25.757813 L 10.171875 25.757813 L 18.5 50.542969 L 27.460938 25.757813 L 33.683594 25.757813 L 42.480469 50.261719 L 50.816406 25.757813 L 61.054688 25.757813 L 45.945313 69.320313 L 39.359375 69.320313 L 30.527344 44.496094 L 21.429688 69.320313 L 14.921875 69.320313 "/>
                    <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(0.799561%,0.799561%,1.199341%)',fillOpacity:1}} d="M 88.488281 42.09375 C 87.765625 39.664063 86.339844 37.6875 84.214844 36.167969 C 82.089844 34.644531 79.621094 33.886719 76.816406 33.886719 C 73.769531 33.886719 71.09375 34.738281 68.792969 36.445313 C 67.351563 37.515625 66.015625 39.398438 64.785156 42.09375 Z M 99.417969 50.699219 L 64.300781 50.699219 C 64.808594 53.796875 66.164063 56.261719 68.367188 58.089844 C 70.570313 59.917969 73.378906 60.832031 76.796875 60.832031 C 80.878906 60.832031 84.390625 59.402344 87.324219 56.546875 C 87.324219 56.546875 94.1875 56.535156 97.945313 56.535156 C 97.945313 58.832031 96.535156 60.871094 96.535156 60.871094 C 94.238281 64.128906 91.488281 66.535156 88.285156 68.097656 C 85.085938 69.660156 81.28125 70.441406 76.875 70.441406 C 70.042969 70.441406 64.476563 68.285156 60.179688 63.972656 C 55.882813 59.664063 53.734375 54.265625 53.734375 47.777344 C 53.734375 41.132813 55.875 35.613281 60.160156 31.222656 C 64.445313 26.832031 69.816406 24.636719 76.273438 24.636719 C 83.136719 24.636719 88.714844 26.832031 93.011719 31.222656 C 97.308594 35.613281 99.457031 41.410156 99.457031 48.617188 "/>
                    <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(0.799561%,0.799561%,1.199341%)',fillOpacity:1,}} d="M 120.800781 34.726563 C 117.355469 34.726563 114.5 35.902344 112.230469 38.25 C 109.964844 40.597656 108.828125 43.667969 108.828125 47.457031 C 108.828125 51.328125 109.964844 54.457031 112.230469 56.847656 C 114.5 59.238281 117.355469 60.429688 120.800781 60.429688 C 124.136719 60.429688 126.945313 59.21875 129.226563 56.789063 C 131.507813 54.359375 132.652344 51.261719 132.652344 47.496094 C 132.652344 43.761719 131.527344 40.699219 129.289063 38.308594 C 127.046875 35.921875 124.214844 34.726563 120.800781 34.726563 M 109.308594 11.367188 L 109.308594 30.5625 C 111.285156 28.585938 113.417969 27.105469 115.714844 26.117188 C 118.007813 25.128906 120.464844 24.636719 123.082031 24.636719 C 128.769531 24.636719 133.597656 26.785156 137.574219 31.082031 C 141.550781 35.378906 143.539063 40.890625 143.539063 47.617188 C 143.539063 54.105469 141.484375 59.53125 137.375 63.894531 C 133.265625 68.257813 128.394531 70.441406 122.761719 70.441406 C 120.253906 70.441406 117.914063 69.992188 115.753906 69.101563 C 113.59375 68.203125 111.441406 66.742188 109.308594 64.714844 L 109.308594 69.320313 L 98.457031 69.320313 L 98.457031 11.367188 "/>
                    <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(97.999573%,65.899658%,9.799194%)',fillOpacity:1}} d="M 36.960938 77.507813 C 33.601563 77.507813 30.816406 78.652344 28.601563 80.945313 C 26.386719 83.238281 25.28125 86.230469 25.28125 89.929688 C 25.28125 93.707031 26.386719 96.757813 28.601563 99.089844 C 30.816406 101.421875 33.601563 102.585938 36.960938 102.585938 C 40.214844 102.585938 42.957031 101.402344 45.183594 99.03125 C 47.410156 96.660156 48.523438 93.640625 48.523438 89.96875 C 48.523438 86.324219 47.429688 83.335938 45.242188 81.003906 C 43.054688 78.675781 40.292969 77.507813 36.960938 77.507813 M 25.75 68.757813 L 25.75 73.445313 C 27.675781 71.519531 29.761719 70.074219 32 69.109375 C 34.238281 68.144531 36.636719 67.664063 39.1875 67.664063 C 44.734375 67.664063 49.445313 69.761719 53.328125 73.953125 C 57.207031 78.144531 59.148438 83.523438 59.148438 90.085938 C 59.148438 96.414063 57.144531 101.707031 53.132813 105.964844 C 49.121094 110.222656 44.367188 112.351563 38.875 112.351563 C 36.425781 112.351563 34.148438 111.914063 32.039063 111.042969 C 29.929688 110.171875 27.832031 108.746094 25.75 106.765625 L 25.75 125.378906 L 15.164063 125.378906 L 15.164063 68.757813 "/>
                    <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(97.999573%,65.899658%,9.799194%)',fillOpacity:1}} d="M 79.945313 77.507813 C 76.453125 77.507813 73.546875 78.675781 71.238281 81.003906 C 68.925781 83.335938 67.773438 86.324219 67.773438 89.96875 C 67.773438 93.640625 68.945313 96.660156 71.300781 99.03125 C 73.652344 101.402344 76.546875 102.585938 79.984375 102.585938 C 83.535156 102.585938 86.480469 101.421875 88.816406 99.089844 C 91.15625 96.757813 92.324219 93.707031 92.324219 89.929688 C 92.324219 86.230469 91.15625 83.238281 88.816406 80.945313 C 86.480469 78.652344 83.523438 77.507813 79.945313 77.507813 M 91.828125 68.757813 L 101.132813 68.757813 L 101.132813 111.257813 L 91.828125 111.257813 L 91.828125 106.765625 C 89.621094 108.746094 87.410156 110.171875 85.191406 111.042969 C 82.96875 111.914063 80.566406 112.351563 77.972656 112.351563 C 72.15625 112.351563 67.125 110.222656 62.878906 105.964844 C 58.632813 101.707031 56.507813 96.414063 56.507813 90.085938 C 56.507813 83.523438 58.5625 78.144531 62.671875 73.953125 C 66.78125 69.761719 71.769531 67.664063 77.644531 67.664063 C 80.34375 67.664063 82.878906 68.144531 85.253906 69.109375 C 87.625 70.074219 89.816406 71.519531 91.828125 73.445313 "/>
                    <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(97.999573%,65.899658%,9.799194%)',fillOpacity:1}} d="M 101.132813 68.757813 L 112.03125 68.757813 L 123.070313 95.425781 L 135.253906 68.757813 L 146.179688 68.757813 L 119.5625 126.804688 L 108.550781 126.804688 L 117.289063 108.085938 "/>
                                       
            </g>
        </Icon>
    )
}
export default Webpay