import React from "react";
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import LogoHeader from '../../assets/img/verticeaustral/logo.png'
function VerticeHeader() {
  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/img/verticeaustral/header.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <div className="motto">
            <img src={LogoHeader} alt="logo-header"/>
            <h2 className="text-center">Buscamos lugares únicos para que puedas cumplir tus sueños</h2>
            <Button color="primary" tag={Link} to="/contactanos" className="mt-4">Contáctanos</Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerticeHeader;
