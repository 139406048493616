import React from "react";
import { Container, Row, Col } from "reactstrap";
import Title from "../Title";
import { Background } from "../Styles";
import Img from "../../assets/img/verticeaustral/servicios1.jpg";
function Servicios() {
	return (
		<Background src={Img}>
			<Container>
				<Row className="pt-1">
					<Col className="pt-md-4">
						<Title
							title="Nuestros servicios"
							color="white"
							width="55%"
							bgcolor="white"
						/>
					</Col>
				</Row>
				<Row className="pt-3 mt-2">
					<Col md="3">
						<div className="info">
							<div className="icon icon-primary">
								<i className="nc-icon icon-gear text-white" />
							</div>
							<div className="description">
								<h4 className="text-white">DESARROLLO DE PROYECTOS</h4>
								<p className="description text-white">
									Siempre con el foco en el medio ambiente y en desarrollar
									proyectos que respeten la geografía y la naturaleza del lugar.
									Creamos lugares de encuentro para la familia y un lugar donde
									poder llevar a cabo sus sueños.
								</p>
							</div>
						</div>
					</Col>
					<Col md="3">
						<div className="info">
							<div className="icon icon-primary">
								<i className="nc-icon icon-eco-home text-white" />
							</div>
							<div className="description">
								<h4 className="text-white">URBANIZACIONES</h4>
								<p className="text-white">
									Tenemos los mejores especialistas en terreno para poder llevar
									a cabo tus proyectos inmobiliarios.
								</p>
							</div>
						</div>
					</Col>
					<Col md="3">
						<div className="info">
							<div className="icon icon-primary">
								<i className="nc-icon icon-handout text-white" />
							</div>
							<div className="description">
								<h4 className="text-white">ASESORIA Y VENTA</h4>
								<p className="text-white">
									Te presentaremos las mejores alternativas de terrenos según
									tus exigencias y necesidades.
								</p>
							</div>
						</div>
					</Col>
					<Col md="3">
						<div className="info">
							<div className="icon icon-primary">
								<i className="nc-icon icon-joint-account text-white" />
							</div>
							<div className="description">
								<h4 className="text-white">MODELO COLABORATIVO</h4>
								<p className="text-white">
									Buscamos que todas las partes involucradas en nuestros
									proyectos sean beneficiadas por el éxito de este. Dueño del
									campo, corredor de propiedades, cliente inversionista y
									cliente Final.
								</p>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Background>
	);
}
export default Servicios;
