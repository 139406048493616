import Styled from 'styled-components'

export const Background = Styled.div`
    background-image: url(${ props => props.src });
    background-position: center 75px;
    background-size: cover;
    width: 100vw;
    height: calc(100vh - 73px);
    padding-top:94px;
`

        
