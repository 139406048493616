import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Badge, CardTitle, CardFooter, Button } from 'reactstrap'
import Webpay from '../Icons'
import Title from '../Title'
import { data } from '../../constants/data.js'
function Proyectos() {
    return (
        <div className="section-gray section">
            <Container>
                <Row className="py-2">
                    <Col>
                        <Title title="Proyectos" width="75%" color="gray" />
                    </Col>
                </Row>
                <Row>
                    {data.map(proyecto =>
                        <Col key={proyecto.id} md="4" sm="6">
                            <Card>
                                <div className="card-image">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="img"
                                            src={proyecto.image}
                                        />
                                    </a>
                                </div>
                                <CardBody>
                                    <Badge color="warning" pill>
                                        {proyecto.type}
                                    </Badge>
                                    <CardTitle tag="h5">
                                        {proyecto.title}
                                    </CardTitle>
                                    <ul style={{ listStyleType: 'none', paddingLeft: 0, paddingTop: '7px' }}>
                                        <li className="d-flex pb-2">
                                            <i className="nc-icon icon-property-location x2 mr-2" />
                                            Loteo {proyecto.info}
                                        </li>
                                        <li className="d-flex pb-2">
                                            <i className="nc-icon icon-calendar-event x2 mr-2" />
                                            {proyecto.entrega}
                                        </li>
                                        <li className="d-flex pb-2">
                                            <i className="nc-icon icon-award-49 x2 mr-2" />
                                            {proyecto.formaPago}
                                        </li>
                                    </ul>
                                    <hr />
                                    <CardFooter>
                                        <Button
                                            outline color="primary"
                                            tag={Link}
                                            to={`/proyectos/${proyecto.id}`}
                                            style={{fontSize:'12px',paddingTop:'10px',paddingBottom:'10px'}}
                                        >
                                            Ver más
                                        </Button>
                                        { proyecto.webpay && 
                                            <Button
                                            outline color="primary"
                                            className="ml-3 px-0"
                                            href={proyecto.webkitURL}
                                            target="blank"
                                        >
                                            <Webpay />
                                        </Button>
                                        }
                                    </CardFooter>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    )
}
export default Proyectos