import React from 'react'
import Styled from 'styled-components'

function Title(props){
    return(
            <H2 width={props.width} color={props.color} bgcolor={props.bgcolor}>
                {props.title}
            </H2>

        )
}
export default Title

const H2 = Styled.h2`
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: left;
    font-size: 2.8rem;
    color: ${props => props.color ? props.color : 'white'};  
    width: 100%;
    &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: ${props => props.width};
    right: 0;
    height: 2px;
    content: '\a0';
    background-color: ${props => props.bgcolor ? props.bgcolor : '#51cbce'};
    }
    @media(max-width:1024px){
        font-size: 2rem; 
        width: 100%;
        &:after {
            width: 0;
    }
}
`