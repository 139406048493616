import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Header from './components/Header'
import Proyectos from './components/Proyectos'
import Nosotros from './components/Nosotros'
import Vende from './components/Vende'
import Contactanos from './components/Contactanos'
import Servicios from './components/Servicios'
import ProyectoDetail from './components/ProyectoDetail'
import Footer from './components/Footer'

function App(){
    return(
        <BrowserRouter>
            <Navbar />
            <div>
                <Switch>
                    <Route path="/" exact component={Header} />
                    <Route path="/nosotros" component={Nosotros}/>
                    <Route path="/servicios" component={Servicios} />
                    <Route path="/proyectos" exact component={Proyectos} />
                    <Route path="/vende" component={Vende} />
                    <Route path="/Contactanos" component={Contactanos} />
                    <Route path="/proyectos/:id" component={ProyectoDetail} />
                </Switch>
            </div>
            <Footer/>
        </BrowserRouter>
    )
}
export default App