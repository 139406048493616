import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

const Footer = () => {
    return(
      <footer className="footer footer-gray footer-white">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="4" sm="12">
              <div className="social-area">
                <Button
                  className="btn-round btn-just-icon mr-1"
                  color="facebook"
                  href="https://www.facebook.com/Vertice-Austral-101655608770830"
                  target='blank'
                 
                >
                  <i className="fa fa-facebook" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="twitter"
                  href="https://www.instagram.com/verticeaustral/?hl=es-la"
                 target='blank'
                >
                  <i className="fa fa-instagram" />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
  
    )
}
export default Footer