import React, { useEffect, useState } from "react";
import { Link, NavLink as LinkRouter, useLocation } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// reactstrap components
import className from 'classnames'
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
// core components
import LogoBlanco from '../../assets/img/verticeaustral/logo.png'
import LogoNegro from '../../assets/img/verticeaustral/logo_negro.png'
function ColorNavbar() {
  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState('')
  const [logo, setLogo] = useState('')
  const location = useLocation()
    
  useEffect(() => {
      switch(location.pathname){
        case '/':
            setColor('navbar-transparent')
            setLogo(LogoBlanco)
            break
        default :
            setColor('')
            setLogo(LogoNegro)
    }
  },[location])
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={className('fixed-top', color)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand className="py-0 my-2" id="navbar-brand" to="/" tag={Link}>
              <img src={logo} style={{width:'140px'}} alt="logo"/>
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink to={{pathname:"/proyectos"}} tag={LinkRouter}>
                        Proyectos
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={{pathname:"/nosotros"}} tag={LinkRouter}>
                        Nosotros
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={{pathname:"/servicios"}} tag={LinkRouter}>
                      Servicios
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={{pathname:"/vende"}} tag={LinkRouter}>
                        Vende tu terreno
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={{pathname:"/contactanos"}} tag={LinkRouter} >
                        Contáctanos
                    </NavLink>
                </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
