import React, { useState } from 'react'
import { Form, FormGroup, Input, Button, Spinner } from 'reactstrap'
import Select from 'react-select'
import Axios from 'axios'

const regionesOptions = [
    { value: "", label: "Selecciona", isDisabled: true },
    { value: "1", label: "I Región de Tarapacá" },
    { value: "2", label: "II Región de Antofagasta" },
    { value: "3", label: "III Región de Atacama" },
    { value: "4", label: "IV Región de Coquimbo" },
    { value: "5", label: "V Región de Valparaiso" },
    { value: "6", label: "VI Región del Libertador General Bernardo O’Higgins" },
    { value: "7", label: "VII Región del Maule" },
    { value: "8", label: "VIII Región del Biobío" },
    { value: "9", label: "IX Región de La Araucanía" },
    { value: "10", label: "X Región de Los Lagos" },
    { value: "11", label: "XI Región Aysén del General Carlos Ibáñez del Campo" },
    { value: "12", label: "XII Región de Magallanes y Antártica Chilena" },
    { value: "13", label: "Región Metropolitana de Santiago" },
    { value: "14", label: "XIV Región de Los Ríos" },
    { value: "15", label: "XV Región de Arica y Parinacota" },
    { value: "16", label: "XVI Región de Ñuble"},
];
const INITIAL_STATE = {
    nombre:'',
    email:'',
    telefono:'',
    sector:'',
    superficie:''
}
function FormularioVenta(){
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(INITIAL_STATE)
    const [regionSelect, setRegionSelect] = useState('')
    
    const handleOnChange = event => {
        setData({...data, [event.target.name]: event.target.value})
    }
    const handleOnSubmit = event => {
        event.preventDefault()
        const { nombre, email, telefono, sector, superficie } = data 
		
		const message = `
			Nombre: ${nombre} 
			Email: ${email} 
			Celular: ${telefono}
            Sector: ${sector}
            Superficie: ${superficie}
			Región: ${regionSelect.label}
        `
        const info = {
            to:'contacto@verticeaustral.com',
            replyTo: email,
            subject:'Formulario Vende tu Terreno',
            text: message
        }

        setIsLoading(true)
        Axios.post('https://us-central1-firemailer.cloudfunctions.net/submitContactoFZ',info)
            .then(res => {
                console.log(`mensaje enviado: ${res.data.isEmailSend}`)
				setIsLoading(false)
            })
            .catch( error => console.log(`ha ocurrido un error ${error}`))
        setData(INITIAL_STATE)
        setRegionSelect('')

    }
    const { nombre, email, telefono, sector, superficie } = data
    const isDisabled = nombre === '' || email === '' || telefono === '' || sector === '' || superficie === '' || regionSelect === ''
    return(
        <Form onSubmit={handleOnSubmit}>
            <p className="text-white text-center">Agenda gratis tu reunión</p>
            <Input
                name="nombre"
                type="text"
                value={nombre}
                onChange={handleOnChange}
                placeholder="Nombre"
                className="mb-2"
                bsSize="sm"
            />
             <Input
                name="email"
                type="email"
                value={email}
                onChange={handleOnChange}
                placeholder="Email"
                className="mb-2"
                bsSize="sm"
            />
             <Input
                name="telefono"
                type="number"
                value={telefono}
                onChange={handleOnChange}
                placeholder="Celular"
                className="mb-2"
                bsSize="sm"
            />
            <FormGroup>
                <Select
                    className="react-select react-select-default"
                    classNamePrefix="react-select"
                    name="regionSelect"
                    value={regionSelect}
                    onChange={value => setRegionSelect(value)}
                    options={regionesOptions}
                    placeholder="Región"
                    bsSize="sm"
                />
            </FormGroup>
            <Input
                name="sector"
                type="text"
                value={sector}
                onChange={handleOnChange}
                placeholder="Sector"
                className="mb-2"
                bsSize="sm"
            />
            <Input
                name="superficie"
                type="text"
                value={superficie}
                onChange={handleOnChange}
                placeholder="Superficie M2"
                className="mb-3"
                bsSize="sm"
            />
            {isLoading? <Spinner className="ml-0 mr-0"/> : 
                <Button 
                    className="w-100" 
                    type="submit"
                    disabled={isDisabled}
                >
                    AGENDA GRATIS TU REUNIÓN
                </Button>
            }
        </Form>
    )
}
export default FormularioVenta