import React, { useState } from "react";
import Axios from 'axios'
import { Form, FormGroup, Input, Button, Label, Spinner } from "reactstrap";

const INITIAL_STATE={
    nombre:'',
    email:'',
    telefono:''
}
const INITIAL_STATE_CHECKBOX={
	inversion:true,
	segundavivienda:false,
	primeravivienda:false,
	dudasyconsultas:false
}
function Formulario() {
	const [data, setData] = useState(INITIAL_STATE);
	const [checkedItems, setCheckedItems] = useState(INITIAL_STATE_CHECKBOX);
	const [isLoading, setIsLoading] = useState(false)

	const handleOnChange = (event) => {
		setData({ ...data, [event.target.name]: event.target.value });
	};
	const handleOnChangeCheckbox = event => {
		setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
	}
	const handleOnSubmit = (event) => {
		event.preventDefault();
		const { nombre, email, telefono } = data 
		const { inversion, segundavivienda, primeravivienda, dudasyconsultas } = checkedItems
		const message = `
			Nombre: ${nombre} 
			Email: ${email} 
			Celular: ${telefono}
			Inversión: ${inversion ? 'Si' : 'No'}
			Segunda Vivienda: ${ segundavivienda ? 'Si' : 'No'}
			Primera Vivienda: ${ primeravivienda ? 'Si' : 'No'}
			Dudas y Consultas: ${ dudasyconsultas ? 'Si' : 'No'}
        `
        const info = {
            to:'contacto@verticeaustral.com',
            replyTo: email,
            subject:'Formulario Contáctanos',
            text: message
        }

		setIsLoading(true)
		Axios.post('https://us-central1-firemailer.cloudfunctions.net/submitContactoFZ',info)
			.then(res => {
				console.log(`mensaje enviado: ${res.data.isEmailSend}`)
				setIsLoading(false)
			})
			.catch(error => console.log(`ha ocurrido un error ${error}`))
		setCheckedItems(INITIAL_STATE_CHECKBOX)
		setData(INITIAL_STATE)
	};
	const { nombre, email, telefono } = data;
	
	const isDisabled = nombre === '' || email === '' || telefono === ''
	return (
		<Form onSubmit={handleOnSubmit}>
			<p className="text-white text-center">Agenda gratis tu reunión</p>
			<Input
				name="nombre"
				type="text"
				value={nombre}
				onChange={handleOnChange}
				placeholder="Nombre"
				className="mb-2"
				bsSize="sm"
			/>
			<Input
				name="email"
				type="email"
				value={email}
				onChange={handleOnChange}
				placeholder="Email"
				className="mb-2"
				bsSize="sm"
			/>
			<Input
				name="telefono"
				type="number"
				value={telefono}
				onChange={handleOnChange}
				placeholder="Celular"
				className="mb-2"
				bsSize="sm"
			/>
            <p className="text-white">¿Que tienes en mente?</p>
			<FormGroup className="bg-white p-2 border-1 rounded" check>
				<Label check>
					<Input
                        name="inversion"
                        type="checkbox" 
                        checked={checkedItems.inversion}
						onChange={handleOnChangeCheckbox}
						bsSize="sm"
                    />
					    Inversión 
                        <span className="form-check-sign" />
				</Label>
			</FormGroup>
			<FormGroup className="bg-white p-2 border-1 rounded" check>
				<Label check>
					<Input
                        name="segundavivienda" 
                        type="checkbox"
                        checked={checkedItems.segundavivienda}
						onChange={handleOnChangeCheckbox}
						bsSize="sm"
                    />
				        Segunda vivienda 
                        <span className="form-check-sign" />
				</Label>
			</FormGroup>
            <FormGroup className="bg-white p-2 border-1 rounded" check>
				<Label check>
					<Input
                        name="primeravivienda"
                        type="checkbox"
						checked={checkedItems.primeravivienda}
						onChange={handleOnChangeCheckbox}
						bsSize="sm"
                    />
					    Primera vivienda 
                        <span className="form-check-sign"/>
				</Label>
			</FormGroup>
            <FormGroup className="bg-white p-2 border-1 rounded" check>
				<Label check>
					<Input  
                        name="dudasyconsultas"
                        type="checkbox"
                        checked={checkedItems.dudasyconsultas}
						onChange={handleOnChangeCheckbox}
						bsSize="sm"
                    />
					    Dudas y consultas 
                        <span className="form-check-sign" />
				</Label>
			</FormGroup>
			{isLoading ? <Spinner />:
				<Button 
					disabled={isDisabled}
					className="w-100" 
					type="submit"
				>
					AGENDA GRATIS TU REUNIÓN
				</Button>
			}
			{/* <p className="text-white text-center">
				En capitalizarme.com no cobramos comisión
			</p> */}
		</Form>
	);
}
export default Formulario;
