import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'
import { data } from '../../constants/data'
import Title from '../Title'

function ProyectoDetail(){
    const { id } = useParams()
    const [proyectoData, setProyectoData] = useState({})

    
    useEffect(() => {
        const getProyecto = () => {
            const filtrado = data.filter( proyecto => proyecto.id === id)
            setProyectoData({...filtrado[0]})
        }
        getProyecto()
    },[id])
    
    return(
        <div className="section">
            <Container>
                <Row className="py-4">
                    <Col>
                        <Title color="gray" width={proyectoData.lineWidth} widthMd={proyectoData.lineWidthmd} title={proyectoData.title}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <iframe width='100%' height='550px' frameBorder="0" src={proyectoData.url} title="masterplan" />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Button
                            tag={Link}
                            to="/proyectos"
                            className="mt-3"
                        >
                            Volver a proyectos
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default ProyectoDetail